import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Contacts from "../components/contact/Contacts";
import Downloads from "../components/contact/Downloads";

export const pageQuery = graphql`
  {
    wpPage(slug: { eq: "contact" }) {
      id
      title
      contactPage {
        description
        contacts {
          email
          mobile
          name
          image {
            ...MediaItem
          }
        }
        downloads {
          label
          items {
            label
            spec
            file {
              id
              localFile {
                url
              }
            }
          }
        }
      }
      pageLinks {
        pageLinks {
          ... on WpPage {
            id
            title
            uri
          }
        }
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  const page = data.wpPage;
  const contacts = page.contactPage.contacts;
  const downloads = page.contactPage.downloads;

  return (
    <Layout title={page.title} page={page}>
      <main>
        <div className="container mx-auto grid md:grid-cols-2 gap-8 px-6 md:px-0">
          {contacts && <Contacts page={page} contacts={contacts} />}
          {downloads && <Downloads downloads={downloads} />}
        </div>
      </main>
    </Layout>
  );
};

export default ContactPage;
